<template>
    <div v-if="incorrectToken || passwordChanged">
        <p class="text-muted mt-2 text-center">
            {{
                passwordChanged
                    ? 'Password has been changed.'
                    : 'Incorrect Token'
            }}
        </p>
        <router-link
            to="/login"
            class="btn btn-block btn-primary waves-effect waves-light mt-3"
        >
            Back to login
        </router-link>
    </div>
    <div v-else>
        <b-form class="mt-3" @submit.prevent="tryToReset">
            <div class="form-group">
                <label for="password">New password</label>
                <input
                    id="password"
                    v-model="password"
                    class="form-control"
                    :class="{
                        'is-invalid': $v.password.$error
                    }"
                    type="password"
                    placeholder="New password"
                />
                <div v-if="passwordErrors.length" class="invalid-feedback">
                    {{ passwordErrors[0] }}
                </div>
            </div>
            <div class="form-group">
                <label for="passwordConfirmation">
                    Password confirmation
                </label>
                <input
                    id="passwordConfirmation"
                    v-model="passwordConfirmation"
                    type="password"
                    class="form-control"
                    :class="{
                        'is-invalid': $v.passwordConfirmation.$error
                    }"
                    placeholder="Password confirmation"
                />
                <div
                    v-if="passwordConfirmationErrors.length"
                    class="invalid-feedback"
                >
                    {{ passwordConfirmationErrors[0] }}
                </div>
            </div>
            <div class="row justify-content-center">
                <button type="submit" class="btn btn-primary">
                    Change password
                </button>
            </div>
        </b-form>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import * as Sentry from '@sentry/vue';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';

export default {
    page: {
        title: 'Set Password'
    },

    validations: {
        password: {
            required,
            minLength: minLength(6),
            containDigit: v => {
                return /\d/.test(v);
            }
        },
        passwordConfirmation: {
            required,
            sameAsPassword: sameAs('password')
        }
    },

    data() {
        return {
            token: null,
            password: '',
            passwordConfirmation: '',
            passwordChanged: false,
            incorrectToken: false
        };
    },

    computed: {
        passwordErrors() {
            const errors = [];
            if (!this.$v.password.$dirty) return errors;
            !this.$v.password.required &&
                errors.push('New Password is required.');
            !this.$v.password.minLength &&
                errors.push(
                    'New Password cannot contain less than 6 characters!'
                );
            !this.$v.password.containDigit &&
                errors.push('New Password must contain numbers and letters!');

            return errors;
        },

        passwordConfirmationErrors() {
            const errors = [];
            if (!this.$v.passwordConfirmation.$dirty) return errors;

            !this.$v.passwordConfirmation.required &&
                errors.push('Password Confirmation is required');
            !this.$v.passwordConfirmation.sameAsPassword &&
                errors.push(
                    'Password confirmation must be equal to New Password'
                );

            return errors;
        }
    },

    created() {
        this.token = this.$route.params.token;
        this.checkToken();
    },

    methods: {
        ...mapActions({
            checkPasswordResetToken: 'auth/checkPasswordResetToken',
            resetPassword: 'auth/resetPassword'
        }),

        async checkToken() {
            try {
                await this.checkPasswordResetToken(this.token);
            } catch (err) {
                console.error(err);

                Sentry.captureException(err);

                this.incorrectToken = true;
            }
        },

        async tryToReset() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.resetPassword({
                    token: this.token,
                    password: this.password,
                    passwordConfirmation: this.passwordConfirmation
                });

                this.passwordChanged = true;
            } catch (err) {
                let message = 'Something went wrong!';

                if (
                    err.response &&
                    err.response.data &&
                    err.response.data.errors
                ) {
                    err.response.data.errors.forEach(error => {
                        message += error + '. ';
                    });
                }

                this.$toasterError(message);
            }
        }
    }
};
</script>
